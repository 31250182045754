@keyframes marquee1 {
  0% {
    transform: translateX(100%); }
  100% {
    transform: translateX(-100%); } }

@keyframes marquee2 {
  0% {
    transform: translateX(100%); }
  100% {
    transform: translateX(-200%); } }

@keyframes marquee3 {
  0% {
    transform: translateX(100%); }
  100% {
    transform: translateX(-300%); } }

@keyframes marquee4 {
  0% {
    transform: translateX(100%); }
  100% {
    transform: translateX(-400%); } }

@keyframes marquee5 {
  0% {
    transform: translateX(100%); }
  100% {
    transform: translateX(-500%); } }

.noselect {
  cursor: default;
  /* http://stackoverflow.com/a/4407335/4956731 */
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE/Edge */
  user-select: none;
  /* non-prefixed version, currently
                                  not supported by any browser */ }

.hidden {
  display: none !important; }

.audio_player {
  display: flex;
  flex-direction: row;
  height: 50px;
  background-color: #333;
  width: 100%;
  /* http://codepen.io/aralon/pen/NqGWXZ */ }
  .audio_player .spacer {
    width: 10px; }
  .audio_player .audio_progress_container {
    position: relative;
    height: 100%;
    flex-grow: 1; }
  .audio_player .audio_progress {
    background-color: #4a4a4a;
    height: 100%;
    width: 0;
    will-change: width; }
  .audio_player .audio_progress_overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
  .audio_player .audio_info_marquee {
    overflow: hidden;
    /* to make room for .audio_time_progress */
    width: calc(100% - 180px);
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 10px; }
  .audio_player .audio_info {
    display: none;
    width: 100%;
    white-space: nowrap;
    word-break: break-all;
    margin: 0;
    font-size: 19.2px;
    color: #fff; }
  .audio_player .audio_time_progress {
    margin-right: 20px;
    align-self: center;
    color: #fff;
    font-size: 23px; }
  .audio_player .audio_button {
    padding-left: 11px;
    padding-right: 11px;
    margin-left: 4px;
    margin-right: 4px;
    cursor: pointer;
    align-self: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 50px; }
  .audio_player .play_pause_button .play_pause_inner {
    height: 30px;
    width: 30px;
    overflow: hidden;
    position: relative; }
  .audio_player .play_pause_button .left {
    height: 100%;
    float: left;
    background-color: #fff;
    width: 36%;
    transition: all 0.25s ease;
    overflow: hidden; }
  .audio_player .play_pause_button .triangle_1 {
    transform: translate(0, -100%); }
  .audio_player .play_pause_button .triangle_2 {
    transform: translate(0, 100%); }
  .audio_player .play_pause_button .triangle_1,
  .audio_player .play_pause_button .triangle_2 {
    position: absolute;
    top: 0;
    right: 0;
    background-color: transparent;
    width: 0;
    height: 0;
    border-right: 30px solid #333;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    transition: transform 0.25s ease; }
  .audio_player .play_pause_button .right {
    height: 100%;
    float: right;
    width: 36%;
    background-color: #fff;
    transition: all 0.25s ease; }
  .audio_player .play_pause_button.paused .left {
    width: 50%; }
  .audio_player .play_pause_button.paused .right {
    width: 50%; }
  .audio_player .play_pause_button.paused .triangle_1 {
    transform: translate(0, -50%); }
  .audio_player .play_pause_button.paused .triangle_2 {
    transform: translate(0, 50%); }
  .audio_player .skip_button.back {
    transform: rotate(180deg); }
  .audio_player .skip_button .skip_button_inner {
    display: flex;
    flex-direction: row; }
  .audio_player .skip_button .right_facing_triangle {
    width: 0;
    height: 0;
    border-left: 16px solid #fff;
    border-top: 16px solid transparent;
    border-bottom: 16px solid transparent; }
  @media screen and (min-width: 370px) {
    .audio_player .audio_info {
      display: initial;
      animation: marquee5 10s cubic-bezier(0, 0.23, 0.6, -0.09) infinite; } }
  @media screen and (min-width: 450px) {
    .audio_player .audio_info {
      animation: marquee4 10s cubic-bezier(0, 0.23, 0.7, -0.09) infinite; } }
  @media screen and (min-width: 550px) {
    .audio_player .audio_info {
      animation: marquee3 10s cubic-bezier(0, 0.23, 0.85, -0.09) infinite; } }
  @media screen and (min-width: 650px) {
    .audio_player .audio_info {
      animation: marquee2 10s cubic-bezier(0, 0.23, 1, -0.09) infinite; } }
  @media screen and (min-width: 720px) {
    .audio_player .audio_info {
      animation: initial; } }
